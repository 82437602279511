interface AdminNavConfigProps {
  intl?: any;
  signOut?: Function;
  afterSignOut?: Function;
}

export const adminNavConfig = ({
  intl,
  signOut,
  afterSignOut,
}: AdminNavConfigProps) => {
  const formatMessage = intl?.formatMessage || (() => '');

  return [
    {
      title: formatMessage({ id: 'shared.concerts' }),
      dataQaidSuffix: 'events-menu',
      childMenu: [
        {
          title: formatMessage({ id: 'admin.navbar.concertPlanner' }),
          link: '/admin/concert-planner',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'event-directory',
        },
        {
          title: formatMessage({ id: 'admin.navbar.broadcasts' }),
          link: '/admin/broadcasts',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'broadcasts',
        },
        {
          title: formatMessage({ id: 'admin.navbar.themes' }),
          link: '/admin/themes',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'themes',
        },
      ],
    },
    {
      title: formatMessage({ id: 'shared.artists' }),
      dataQaidSuffix: 'artists-menu',
      childMenu: [
        {
          title: formatMessage({ id: 'admin.navbar.artistDirectory' }),
          link: '/admin/artists',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'artist-directory',
        },
        {
          title: formatMessage({ id: 'admin.navbar.artistApplications' }),
          link: '/admin/artist-applications',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'artist-applications',
        },
        {
          title: formatMessage({ id: 'admin.navbar.bookingRequests' }),
          link: '/admin/booking-requests',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'artist-booking-requests',
        },
        {
          title: formatMessage({ id: 'admin.shared.artistBookingInvitations' }),
          link: '/admin/artist-booking-invitations',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'artist-booking-invitations',
        },
        {
          title: formatMessage({ id: 'admin.navbar.artistMerges' }),
          link: '/admin/artist-merge',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'artist-merges',
        },
      ],
    },
    {
      title: formatMessage({ id: 'shared.hosts' }),
      dataQaidSuffix: 'venues-menu',
      childMenu: [
        {
          title: formatMessage({ id: 'admin.navbar.venueDirectory' }),
          link: '/admin/venues',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'venue-directory',
        },
        {
          title: formatMessage({ id: 'admin.navbar.venueApplications' }),
          link: '/admin/venue-applications',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'venue-applications',
        },
      ],
    },
    {
      title: formatMessage({ id: 'shared.crew' }),
      dataQaidSuffix: 'crew-menu',
      childMenu: [
        {
          title: formatMessage({ id: 'admin.navbar.crewPortal' }),
          link: '/admin/crew-portal',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'crew-portal',
        },
        {
          title: formatMessage({ id: 'admin.navbar.crewDirectory' }),
          link: '/admin/crew-directory',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'crew-directory',
        },
      ],
    },
    {
      title: formatMessage({ id: 'admin.navbar.marketing' }),
      dataQaidSuffix: 'marketing-menu',
      childMenu: [
        {
          title: formatMessage({ id: 'admin.navbar.eventCollections' }),
          link: '/admin/collections',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'eventCollections',
        },
        {
          title: formatMessage({ id: 'admin.navbar.campaigns' }),
          link: '/admin/campaigns',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'campaigns',
        },
        {
          title: formatMessage({ id: 'shared.partners' }),
          link: '/admin/partners',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'partners',
        },
        {
          title: formatMessage({ id: 'admin.navbar.competitions' }),
          link: '/admin/competitions',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'competitions',
        },
        {
          title: formatMessage({ id: 'admin.navbar.promoCodes' }),
          link: '/admin/promo-codes',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'promo-codes',
        },
        {
          title: formatMessage({ id: 'admin.navbar.siteBanner' }),
          link: '/admin/site-banner',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'site-banner',
        },
      ],
    },
    {
      title: formatMessage({ id: 'admin.navbar.users' }),
      dataQaidSuffix: 'users-menu',
      alignRight: true,
      childMenu: [
        {
          title: formatMessage({ id: 'admin.navbar.userDirectory' }),
          link: '/admin/users',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'user-directory',
        },
        {
          title: formatMessage({ id: 'admin.navbar.internalUserDirectory' }),
          link: '/admin/internal-users',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'internal-user-directory',
        },
        {
          title: formatMessage({ id: 'admin.shared.curatorGroups' }),
          link: '/admin/curator-groups',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'curator-groups',
        },
        {
          title: formatMessage({ id: 'admin.navbar.rolesAndPermissions' }),
          link: '/admin/roles-and-permissions',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'roles-and-permissions',
        },
        {
          title: formatMessage({ id: 'admin.navbar.gdprDataRemovalForm' }),
          link: '/admin/gdpr-data-removal-form',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'gdpr-data-removal-form',
        },
      ],
    },
    {
      title: formatMessage({ id: 'admin.navbar.tools' }),
      dataQaidSuffix: 'tools-menu',
      alignRight: true,
      childMenu: [
        {
          title: formatMessage({ id: 'admin.navbar.businessOwnerDirectory' }),
          link: '/admin/business-owners',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'business-owners',
        },
        {
          title: formatMessage({ id: 'admin.navbar.cityDirectory' }),
          link: '/admin/city-directory',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'city-directory',
        },
        {
          title: formatMessage({ id: 'admin.navbar.countries' }),
          link: '/admin/countries',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'countries',
        },
        {
          title: formatMessage({ id: 'admin.navbar.emailDirectory' }),
          link: '/admin/emails',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'email-directory',
        },
        {
          title: formatMessage({ id: 'admin.navbar.emailTestTool' }),
          link: '/admin/email-testing',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'dev-email-test',
        },
        {
          title: formatMessage({ id: 'admin.navbar.customEmailTemplates' }),
          link: '/admin/custom-email-templates',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'custom-email-templates',
        },
        {
          title: formatMessage({ id: 'admin.navbar.versions' }),
          link: '/admin/versions',
          permission: 'usePermissionForLink',
          dataQaidSuffix: 'versions',
        },
        {
          title: formatMessage({ id: 'admin.navbar.flipper' }),
          link: '/dev/flipper',
          permission: 'tool.flipper.access',
          dataQaidSuffix: 'dev-flipper',
          openInNewTab: true,
        },
        {
          title: formatMessage({ id: 'admin.navbar.sidekiq' }),
          link: '/dev/sidekiq',
          permission: 'tool.sidekiq.access',
          dataQaidSuffix: 'dev-sidekiq',
          openInNewTab: true,
        },
        {
          title: formatMessage({ id: 'admin.navbar.prismicReference' }),
          link: 'https://dev.sofarsounds.com/v/prismic-reference',
          permission: 'tool.prismicReference.access',
          dataQaidSuffix: 'dev-prismic-reference',
          openInNewTab: true,
        },
      ],
    },
    {
      title: formatMessage({ id: 'navbar.help' }),
      dataQaidSuffix: 'help-menu',
      alignRight: true,
      collapseSingleChild: true,
      childMenu: [
        {
          title: 'Help Center',
          link: 'https://help.sofarsounds.com',
          dataQaidSuffix: 'help-center',
          openInNewTab: true,
        },
      ],
    },
    {
      title: formatMessage({ id: 'admin.navbar.settings' }),
      dataQaidSuffix: 'settings-menu',
      alignRight: true,
      childMenu: [
        {
          title: formatMessage({ id: 'admin.navbar.signOut' }),
          link: '',
          dataQaidSuffix: 'sign-out',
          onClick: async () => {
            signOut && (await signOut());
            afterSignOut && afterSignOut();
          },
        },
      ],
    },
  ];
};
