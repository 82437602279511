import React from 'react';

import { useCurrentTheme } from 'app/shared/theme';
import { Box } from 'app/shared/components/atoms';
import { IconButton } from 'app/shared/components/molecules/IconButton';
import { ReactComponent as Close } from 'icons/close.svg';

interface Props {
  disableClose: boolean;
  onClick: () => void;
  ariaLabel?: string;
  'data-qaid'?: string;
}

export const ModalCloseButton = ({
  disableClose,
  onClick,
  ariaLabel = 'close modal',
  'data-qaid': qaId = 'close-modal-button',
}: Props) => {
  const { colors } = useCurrentTheme();

  if (disableClose) {
    return null;
  }

  return (
    <Box justifyContent="flex-end">
      <IconButton
        data-qaid={qaId}
        aria-label={ariaLabel}
        icon={Close}
        onClick={onClick}
        backgroundColor="unset"
        strokeColor={colors.accent.primaryAccent.base}
        fillColor="none"
        size="md"
        position="relative"
        p={0}
        m={0}
      />
    </Box>
  );
};
