import Cookies from 'js-cookie';

export function setTikTokCookies(searchParams: URLSearchParams) {
  const aYearFromNow = new Date(Date.now() + 31536000000); // 365 * 24 * 60 * 60 * 1000
  const aWeekFromNOw = new Date(Date.now() + 604800000); // 7 * 24 * 60 * 60 * 1000
  const tikTokClickId = searchParams.get('ttclid');
  const tikTokTestId = searchParams.get('tt_test_id');
  const isNotProd = process.env.APP_ENV !== 'live';

  if (tikTokClickId) {
    Cookies.set('ttclid', tikTokClickId, {
      expires: aYearFromNow,
    });
  }
  if (tikTokTestId && isNotProd) {
    Cookies.set('tt_test_id', tikTokTestId, {
      expires: aWeekFromNOw,
    });
  }
  if (!isNotProd) {
    Cookies.remove ? Cookies.remove('tt_test_id') : console.log({ Cookies });
  }
}
