import Cookies from 'js-cookie';

const ONE_HOUR_FROM_NOW = new Date(Date.now() + 3600000); // 60 * 60 * 1000
const NUM_PAGE_RELOADS_COOKIE_NAME = 'numPageReloads';
const PAGE_LAST_RELOADED_COOKIE_NAME = 'pageLastReloaded';
const MAX_NUM_PAGE_RELOADS = 3;
const TIME_BETWEEN_RELOADS = 1000; // In milliseconds

const isReadyForReloadPage = () => {
  const pageLastReloaded = Cookies.get
    ? Cookies.get(PAGE_LAST_RELOADED_COOKIE_NAME)
    : undefined;
  const now = new Date().getTime();
  const timeSinceLastReload = pageLastReloaded
    ? now - parseInt(pageLastReloaded)
    : undefined;

  return !timeSinceLastReload ||
    (timeSinceLastReload && timeSinceLastReload >= TIME_BETWEEN_RELOADS)
    ? true
    : false;
};

const reloadPage = (numPageReloads: number) => {
  const now = new Date().getTime();
  Cookies.set(PAGE_LAST_RELOADED_COOKIE_NAME, now.toString(), {
    expires: ONE_HOUR_FROM_NOW,
  });
  Cookies.set(NUM_PAGE_RELOADS_COOKIE_NAME, (numPageReloads + 1).toString(), {
    expires: ONE_HOUR_FROM_NOW,
  });

  window.location.reload();
};

const pageUrl = `${window.location.pathname}${window.location.search}`;

export const handleAutomaticPageReload = () => {
  const getNumPageReloadsCookie = () => {
    if (Cookies.get) {
      return Cookies.get(NUM_PAGE_RELOADS_COOKIE_NAME);
    }

    console.log({ Cookies });
    return MAX_NUM_PAGE_RELOADS;
  };
  const numPageReloadsCookie = getNumPageReloadsCookie();
  const numPageReloads = Number(numPageReloadsCookie || 0);

  if (numPageReloads >= MAX_NUM_PAGE_RELOADS) {
    console.log(`COMPLETED AUTOMATIC PAGE RELOAD SEQUENCE ON PAGE: ${pageUrl}`);
    // DONE with automatic page reload sequence
    return true;
  }

  // Just in case something went wrong, exit loop after a really long time to prevent infinite loop
  const maxNumChecks = 1000000;
  let numChecks = 0;

  while (numChecks <= maxNumChecks) {
    if (isReadyForReloadPage()) {
      reloadPage(numPageReloads);
      break;
    }

    numChecks++;
  }

  // NOT DONE with automatic page reload sequence
  return false;
};
